import React, { Component } from 'react';
// import GLogo from '../assets/img/google-logo.png';
// import FLogo from '../assets/img/facebook-logo.png';
import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';

class ResetPassword extends Component {
  state = {
    number: '',
    password: '',
    cpassword: '',
    showStep: 'first',
    showPassword: false,
    otp: '',
    disableOtp: false,
  };

  calculateMinutes = (oldTime) => {
    const now = new Date();
    const difference = now.getTime() - oldTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  };

  componentDidMount() {
    this.context.setPageTitle('Reset Password');
    window.scrollTo(0, 0);
    const existing = localStorage.getItem('otp-details-reset');
    const token = localStorage.getItem('token');
    token ? this.props.history.push('/') : console.log('Not logged in');

    if (existing) {
      const data = JSON.parse(existing);
      // //console.log(data);
      const minutes = this.calculateMinutes(new Date(data.created_at));
      // //console.log(minutes);

      if (1440 < minutes) {
        //console.log('clear localstorage');
        localStorage.clear();
        this.setState({ disableOtp: false });
      } else if (1440 > minutes && data.counter < 3) {
        // //console.log('Continue');
        this.setState({ disableOtp: false });
      } else {
        this.setState({ disableOtp: true });
      }
    } else {
      this.setState({ disableOtp: false });
    }
  }

  otpDisableFunction = () => {
    setTimeout(() => {
      this.setState({ disableOtp: false });
    }, 30000);
  };

  handleFirstStep = (e) => {
    e.preventDefault();
    // let numberPattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(this.state.number)) {
      toast.error('Please enter a valid mobile number!', {
        autoClose: 3000,
      });
    } else {
      this.setState({ disableOtp: true });
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/send-otp-password/${this.state.number}`
        )
        .then((resp) => {
          // //console.log(resp.data);
          if (resp.data.status === 1) {
            this.setState({ showStep: 'second' });
            toast.success(`OTP has been sent to your mobile, please check.`, {
              autoClose: 3000,
            });
            this.otpDisableFunction();
          } else {
            toast.error('User not found, Register a new account.', {
              autoClose: 3000,
            });
          }
          this.setState({ disableOtp: false });
        })
        .catch((error) => {
          this.setState({ disableOtp: false });
          toast.error('User not found, Register a new account.', {
            autoClose: 3000,
          });
          // //console.log(error.response.status);
          // //console.log(error.response.data);
        });
    }
  };

  handleSecondStep = (e) => {
    e.preventDefault();
    if (this.state.otp === '') {
      toast.error(`Please enter OTP.`, {
        autoClose: 3000,
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/match-otp/?mobile=${this.state.number}&page=1&limit=1&ofset=0`
        )
        .then((resp) => {
          const dbCode = resp.data.results[0].code;
          // //console.log(dbCode);
          if (dbCode === parseInt(this.state.otp)) {
            this.setState({ showStep: 'third' });
          } else if (dbCode !== parseInt(this.state.otp)) {
            toast.error(`OTP doesn't matched, please try again.`, {
              autoClose: 3000,
            });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          //console.log(error.response.status);
          //console.log(error.response.data);
        });
    }
  };

  handleThirdStep = (e, toggleLoggedIn, setUserMyself) => {
    e.preventDefault();
    if (this.state.password === '') {
      toast.error('Please enter new password!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.cpassword === '') {
      toast.error('Please enter confirm password!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.cpassword !== this.state.password) {
      toast.error('Password and confirm password not same!', {
        autoClose: 3000,
      });
      return;
    }

    const postData = {
      username: this.state.number,
      code: this.state.otp,
      password: this.state.password,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user_management/forgot-password`,
        postData
      )
      .then((resp) => {
        // //console.log(resp.data);

        toast.success(resp.data.message, {
          autoClose: 3000,
        });
        localStorage.clear();
        if (resp.data.status && resp.data.status === 1) {
          window.setTimeout(() => {
            this.props.history.push('/login');
          }, 3000);
        }
      })
      .catch((error) => {
        toast.error('Sorry something went wrong, please try again later.', {
          autoClose: 3000,
        });
      });
  };

  handleOTP = () => {
    this.setState({ disableOtp: true });
    const existing = localStorage.getItem('otp-details-reset');
    if (existing) {
      const data = JSON.parse(existing);
      if (data.counter < 3) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/send-otp-password/${this.state.number}`
          )
          .then((resp) => {
            // //console.log(resp.data)

            if (resp.data.status === 1) {
              toast.success(`OTP has been sent to your mobile, please check.`, {
                autoClose: 3000,
              });
              data.counter++;
              data.created_at = new Date();
              localStorage.setItem('otp-details-reset', JSON.stringify(data));
              this.otpDisableFunction();
            } else {
              toast.error('User not found, Register a new account.', {
                autoClose: 3000,
              });
            }
          })
          .catch((error) => {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          });
      } else {
        // //console.log('disable');
        this.setState({ disableOtp: true });
        toast.error('Please try again 24 hours later later.', {
          autoClose: 3000,
        });
      }
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/send-otp-password/${this.state.number}`
        )
        .then((resp) => {
          if (resp.data.status === 1) {
            toast.success(
              `We will send an OTP to ${this.state.number}, please check.`,
              {
                autoClose: 3000,
              }
            );
            const data = {
              counter: 1,
              created_at: new Date(),
            };
            localStorage.setItem('otp-details-reset', JSON.stringify(data));
            this.otpDisableFunction();
          } else {
            toast.error('User not found, Register a new account.', {
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 3000,
          });
        });
    }
  };

  render() {
    const { toggleLoggedIn, setUserMyself } = this.context;
    return (
      <div>
        <section className='losign signup'>
          <div className='container'>
            <div className='row justify-content-center'>
              {this.state.showStep === 'first' && (
                <div className='col-md-6 first'>
                  <h2 className='title'>Reset Password</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form onSubmit={this.handleFirstStep}>
                    <div className='form-group for-otp'>
                      <input
                        type='number'
                        placeholder='Mobile Number'
                        value={this.state.number}
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                      <button
                        className='btn btn-primary btn-sm'
                        type='submit'
                        disabled={
                          this.state.disableOtp ? 'disabled' : ''
                        } /*disabled */
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='Enter OTP'
                        className='opt'
                        disabled
                      />
                    </div>
                    <div className='form-group'>
                      <button
                        type='submit'
                        className='submit-button disabled'
                        disabled
                      >
                        Validate
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {this.state.showStep === 'second' && (
                <div className='col-md-6 second'>
                  <h2 className='title'>Reset Password</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form onSubmit={this.handleSecondStep}>
                    <div className='form-group for-otp'>
                      <input
                        type='number'
                        placeholder='+88 01765113131'
                        value={this.state.number}
                        disabled
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                      <button
                        className={`btn btn-primary btn-sm ${
                          this.state.disableOtp ? 'disabled' : ''
                        }`}
                        disabled={this.state.disableOtp ? 'disabled' : ''}
                        type='button'
                        onClick={this.handleOTP}
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='Enter OTP'
                        value={this.state.otp}
                        onChange={(e) => this.setState({ otp: e.target.value })}
                      />
                    </div>
                    <div className='form-group'>
                      <button type='submit' className='submit-button'>
                        Validate OTP
                      </button>
                    </div>
                  </form>
                  <div className='bottom'>
                    An OTP has been sent to your mobile. It will expire within
                    next
                    <span> 3 minutes</span>
                  </div>
                </div>
              )}
              {this.state.showStep === 'third' && (
                <div className='col-md-6 third'>
                  <h2 className='title'>Reset Password</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form
                    onSubmit={(e) =>
                      this.handleThirdStep(e, toggleLoggedIn, setUserMyself)
                    }
                  >
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='+8801765113131'
                        value={this.state.number}
                        disabled
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                    </div>
                    {/* <div className='form-group'>
                      <input
                        type='text'
                        placeholder='Enter your name'
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div> */}
                    <div className='form-group'>
                      <div className='show-hide'>
                        <input
                          type={this.state.showPassword ? 'text' : 'password'}
                          placeholder='Enter new password'
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                        <img
                          src={this.state.showPassword ? EyeOff : Eye}
                          alt=''
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='show-hide'>
                        <input
                          type={this.state.showCPassword ? 'text' : 'password'}
                          placeholder='Enter confirm password'
                          value={this.state.cpassword}
                          onChange={(e) =>
                            this.setState({ cpassword: e.target.value })
                          }
                        />
                        <img
                          src={this.state.showCPassword ? EyeOff : Eye}
                          alt=''
                          onClick={() =>
                            this.setState({
                              showCPassword: !this.state.showCPassword,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <button type='submit' className='submit-button'>
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ResetPassword;

ResetPassword.contextType = AuthContext;
