import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccArrow from '../assets/img/right-arrow-link.png';
import { AuthContext } from '../context/AuthContext';
import Logo from '../assets/img/profile.png';
import tazin from '../assets/img/tazin-shadid.jpeg';
import zahid from '../assets/img/istiak-jahid.jpg';
import tahreem from '../assets/img/tahreem-shah.jpeg';

const About = () => {
  const { setPageTitle } = useContext(AuthContext);
  const [idx, setIdx] = useState([111, 222, 333]);

  useEffect(() => {
    setPageTitle('About Us');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about'>
      <section>
        <div className='container text-justify team-mobile'>
          <div className='row mb-3'>
            <div className='col-12'>
              <h3 className='title mb-3'>About the Team</h3>
              {/*
              <section>
                <p>
                  The revolution of digital healthcare service has been apparent
                  worldwide over the last two years in the context of the
                  coronavirus pandemic. With the accessibility to health checkup
                  facilities at home, the popularity of at-home pathology
                  service is growing continuously. Bangladesh is no different.
                  There has been the emergence of several healthcare service
                  providers. AmarLab, a digital healthcare startup that provides
                  at-home diagnostic test services is amongst the pioneers of
                  such facilitators in Bangladesh.
                </p>
              </section>
              */}
            </div>
          </div>

          <div className='row mb-3'>
            
              
              <section className='row'>
              <div className='col-md-3'>
                <img src={tazin} style={{ float: 'left', marginRight: '40px', marginBottom: '70px', marginTop: '10px', borderRadius: '100%' }}  alt='co-founder' width='200' className='web' />
              </div>
                <div className='col-md-9'>
                  <h4  className='subtitle mb-3 subtitle-member'>Tazin Shadid</h4>
                  <span className='member-caption'>Co-Founder and CEO</span>
                  <p style={{ marginTop: '15px'}}>Tazin is on a mission to build a world where everyone has easy access to quality healthcare. He is the co-founder of AmarLab, a health-tech startup that has built the first diagnostics marketplace in Bangladesh. A practitioner of Human Centered Design for over 20 years, Tazin spent 10 years at Microsoft Headquarters as a Senior Design Strategist building innovative products and services. Tazin is the founder of Spreeha, a non-profit that designs creative solutions to some of the toughest social issues of our times. He has also co-founded multiple startups such as neofarmers, Spider Digital, Cholo Shobai, and Ooroh Venture Studio. Tazin also works with global brands in advisory roles on topics related to design, UX/CX, leadership, organizational strategy, entrepreneurship, non-profit management, public health, etc..{' '}
                </p>
                </div>
                            
              </section>

          </div>


          <div className='row mb-3'>
            
              
              <section className='row'>
                <div className='col-md-3'>
                  <img src={zahid} style={{ float: 'left', marginRight: '40px', marginBottom: '70px', marginTop: '10px', borderRadius: '100%' }}  alt='co-founder' width='200' className='web' />
                </div>
                <div className='col-md-9'>
                  <h4 className='subtitle subtitle-member mb-3'>Dr. Ishtique Zahid</h4>
                  <span className='member-caption'>Co-Founder and COO</span>  
                  <p style={{ marginTop: '15px'}}>Dr. Ishtique Zahid brings over 15 years of healthcare experience to AmarLab as its Chief Operating Officer. His journey began as an Emergency Room Physician at Ibn Sina Medical College Hospital, where he honed his skills in managing acute medical conditions. Dr. Zahid's entrepreneurial drive led to the co-founding of AmarLab, where his visionary leadership has transformed the company into a data-driven healthcare powerhouse. He excels in data analysis and financial modeling, driving critical business insights and informed decision-making. His dedication to service excellence is reflected in comprehensive service protocols, training manuals, and standardized procedures that ensure top-tier healthcare services. Dr. Zahid's innovative collaboration with PUM Netherlands has resulted in a groundbreaking temperature-controlled solution for safe sample transportation, revolutionizing diagnostics in resource-constrained settings. His commitment to compassionate patient care and a multicultural perspective further underscore his impact on the healthcare landscape.{' '}
                </p>
                </div>
                
              </section>
            
          </div>

          <div className='row mb-3'>
              
              <section className='row'>
                <div className='col-md-3'>
                  <img src={tahreem} style={{ float: 'left', marginRight: '40px', marginBottom: '70px', marginTop: '10px', borderRadius: '100%' }}  alt='co-founder' width='200' className='web' />
                </div>
                <div className='col-md-9'>
                  <h4 className='subtitle subtitle-member mb-3'>Tahreem Shah</h4>
                  <span   className='member-caption'>Co-Founder and Head of Growth</span>
                  <p style={{ marginTop: '15px'}}>Tahreem Shah is an architect by trade and a social entrepreneur by passion, Tahreem Shah has dedicated herself to finding innovative solutions for the underserved population. Her unique journey seamlessly integrates design, business, and technology. Currently, Tahreem serves as the co-founder and Head of Growth for AmarLab, Bangladesh's pioneering and largest diagnostic marketplace, where she drives growth, innovation and digital transformation. Tahreem made a career pivot following an architectural internship in Norway and got deeply involved in local & global initiatives that focus on urban disaster preparedness–in particular those that focus on architectural design and financial inclusion of low income communities. Tahreem is the co-founder of the social-impact business 'Bhorosha' and has dedicated her work to contribute to socially responsive architecture and find solutions for informal settlements.</p>{' '}
                </div>
                
              </section>
          </div>


        </div>
      </section>
    </div>
  );
};

export default About;
